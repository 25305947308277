



































































import {
  Component, PropSync, Vue, Watch,
} from 'vue-property-decorator';

import StepChooseGoalsWealthViewModel
  from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/step-choose-goals-wealth-view-model';

@Component({ name: 'StepChooseGoalsWealth' })
export default class StepChooseGoalsWealth extends Vue {
  @PropSync('associatedProductId', { type: String, required: true })
  associated_product_id!: string;

  @PropSync('isLoading', { type: Boolean, required: true, default: false })
  is_loading!: boolean;

  @Watch('step_choose_goals_wealth_model.associated_product_selected')
  associatedProductSelected() {
    this.$emit('changeGoalsSelected', this.step_choose_goals_wealth_model.associated_product_selected);
  }

  step_choose_goals_wealth_model = Vue.observable(
    new StepChooseGoalsWealthViewModel(this),
  );

  created() {
    this.step_choose_goals_wealth_model.initialize(this.associated_product_id);
  }
}
